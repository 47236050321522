import { useLocation } from "@reach/router"
import { graphql, Link, Script, StaticQuery } from "gatsby"
import { parse as queryparse } from "query-string"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Marquee from "react-fast-marquee"
import LongArrow from "../assets/images/longArrow.png"
import ErrorIcon from "../assets/images/signup-error.svg"
import FormBuilder from "../components/common/FormBuilder/Form-Builder"
import Frame from "../components/common/frame"
import SVGIcon from "../components/common/SVGIcon"
import Navigation from "../components/navigation"
import CTAThankYou from "../components/utility/cta_thank_you"
import Close from "./../assets/images/close-white.png"

// import "./../../static/chillipiper"
const GrowDemoEset = ({ location }) => {
  const [showForm, setShowForm] = useState(false)
  const [errorText, setErrorText] = useState("")
  const [currentLocation, setCurrentLocation] = useState("")
  const locationnew = useLocation()
  const searchParams = queryparse(locationnew.search)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const fiveStar = [1, 1, 1, 1, 1]

  useEffect(() => {
    searchParams.src !== "xero" &&
      formdata.push({
        name: "mobilephone",
        type: "phone",
        label: "Mobile number",
        value: searchParams.phoneNumber ?? null,
        errorMessage: "Please enter a mobile number",
      })
  }, [searchParams])
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      value:
        // (typeof sessionStorage !== "undefined" &&
        //   sessionStorage.getItem("firstname")) ||
        searchParams.name ?? "",
      errorMessage: "Please enter a name",
    },
    {
      name: "email",
      type: "email",
      label: "Business email",
      value:
        // (typeof sessionStorage !== "undefined" &&
        //   sessionStorage.getItem("email")) ||
        searchParams.mail ?? "",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      value:
        // (typeof sessionStorage !== "undefined" &&
        //   sessionStorage.getItem("company")) ||
        searchParams.company ?? "",
      errorMessage: "Please enter a company name",
    },

    // {
    //   name: "mobilephone",
    //   type: "phone",
    //   label: "Mobile number",
    //   value:
    //     // (typeof sessionStorage !== "undefined" &&
    //     //   sessionStorage.getItem("mobilephone")) ||
    //     null,
    //   errorMessage: "Please enter a mobile number",
    // },

    // {
    //   name: "current_tool_set",
    //   type: "select",
    //   label: "Choose what you currently use",
    //   value: null,
    //   choices: [
    //     { label: "Datto", value: "Datto" },
    //     { label: "NinjaRMM", value: "NinjaRMM" },
    //     { label: "Kaseya", value: "Kaseya" },
    //     { label: "Atera", value: "Atera" },
    //   ],
    //   errorMessage: "Please select a value",
    // },
  ]

  const handleAfterSubmit = (res, items) => {
    // let toolset = items.filter(el => el.name === "current_tool_set")[0]?.value
    // if (toolset.includes("Datto") || toolset.includes("Autotask")) {
    // navigate(`/thank-you?src=demo`, { state: { showVideo: true } })
    // }
    //  else {
    const formData = items.reduce((memo, curr) => {
      memo[curr.name] = curr.value
      return memo
    }, {})
    if (res?.status == 200) {
      window.postMessage({ type: "rhFormSubmit", formData })
      setSubmitSuccess(true)
    }
    // }
  }

  //   useEffect(() => {
  //     if (document.referrer != "") {
  //       setShowForm(true)
  //     }
  //   }, [])

  return (
    <StaticQuery
      query={graphql`
        query contentGrowDemoEset {
          SuperOps {
            pages(where: { title: "Demo New" }) {
              title
              newField {
                ... on SuperOps_Hero {
                  __typename
                  tag
                  tagLink
                  heroImage {
                    url
                  }
                  backgroundImage {
                    url
                  }
                  heroExcerptList
                  secondaryTag
                  headingLine2
                }
                ... on SuperOps_Testimonial {
                  testimonialContent
                  testimonialLink
                  testimonialTitle
                  testimonialName
                  testimonialDescription
                  testimonialImage {
                    url
                  }
                }
              }

              seo {
                title
                description
                keywords
                image {
                  url
                }
              }
            }
            peopleList(where: { page_contains: "Home" }) {
              page
              name
              title
              testimonial
              background
              cardBgColor
              thumbnail {
                url(
                  transformation: {
                    document: { output: { format: webp } }
                    image: { resize: { width: 110 } }
                  }
                )
              }
              picture {
                url(
                  transformation: {
                    document: { output: { format: webp } }
                    image: { resize: { width: 500 } }
                  }
                )
              }
              titleText {
                html
              }
              descriptionText {
                html
              }
            }
          }
        }
      `}
      render={data => (
        <div className="contact-us demoPage">
          {data.SuperOps.pages.map(page => {
            const { newField, seo } = page
            return (
              <>
                <Script
                  src="https://app.revenuehero.io/scheduler.min.js"
                  onLoad={() => {
                    window.addEventListener("message", e => {
                      if (e.data.type === "rhFormSubmit") {
                        const hero = new window.RevenueHero({ routerId: "979" })
                        hero
                          .submit(e.data.formData)
                          .then(data => {
                            hero.dialog.setEmbedTarget("#SuperOpsusdemoform")
                            hero.dialog.open(data)
                          })
                          .catch(error => console.error(error))
                      }
                    })
                  }}
                />
                <Frame
                  seo={seo}
                  footerClassName="demoPage"
                  revenueHeroDemo
                  demoForm
                >
                  <header>
                    <Navigation color={"#FFD7DB"} page={"Public Beta"} />
                  </header>

                  <section
                    className="hero"
                    style={{
                      backgroundImage: `url(${newField[0]?.backgroundImage?.url})`,
                    }}
                  >
                    <Container className="demoPage-container">
                      <Row>
                        <Col lg={6}>
                          <div className="cn-left">
                            <div className="alt mb24 down-to-up-1">
                              <h2 className="fw-bold">{newField[0].tag}</h2>
                            </div>
                            <div className="details down-to-up-2">
                              <div className="altv2 p18 mb24">
                                <p className="text-grey m-0">
                                  {newField[0].tagLink}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex flex-column rg-24 down-to-up-3">
                              {newField[0].heroExcerptList.map(point => {
                                return (
                                  <div className="d-flex gap-3 align-items-center">
                                    <SVGIcon
                                      name="ebook/Done-icon"
                                      className="prelative point-icon"
                                    />
                                    <p className="p16 m-0 w-75 fw-normal text-grey">
                                      {point}
                                    </p>
                                  </div>
                                )
                              })}
                            </div>
                            <div className="d-flex justify-content-between align-items-center p24 mt80 badge-section down-to-up-4">
                              <p className="testimonial-title fw-bold m-0">
                                {newField[0].headingLine2}
                              </p>
                              <div style={{ maxWidth: "200px" }}>
                                <img
                                  src={newField[0].heroImage.url}
                                  alt="badge"
                                  className="w-100"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="cn-form down-to-up-2">
                            <center>
                              <section className="cn-cta bts-cta-cnt">
                                <Container className="position-relative">
                                  {/* <span className="info">
                                      <center>
                                        <GetSVG
                                          name="calender"
                                          className="mb-4 mt-2"
                                        />
                                        <div className="p24 ">
                                          {parse(pageBlock[3].content.html)}
                                        </div>
                                        <Buttons
                                          theme="primary w-100"
                                          text="BOOK A DEMO"
                                          onClick={() => setShowForm(true)}
                                        />
                                      </center>
                                    </span> */}
                                  {currentLocation !== "IN" && (
                                    <div className="d-flex justify-content-between index-parent">
                                      <div className="index-wrapper altv2 d-flex flex-column align-items-center p13">
                                        <div
                                          className={`circle d-flex align-items-center justify-content-center ${!submitSuccess &&
                                            "active"}`}
                                        >
                                          <h4 className="fw-bolder m-0">01</h4>
                                        </div>
                                        <p
                                          className={`sub-heading m-0 ${!submitSuccess &&
                                            "active"}`}
                                        >
                                          Details
                                        </p>
                                      </div>
                                      <div className={`form-line`}>
                                        <img
                                          src={LongArrow}
                                          alt="long-arrow"
                                          className="w-100 mt25"
                                        />
                                      </div>
                                      <div className="index-wrapper altv2 d-flex flex-column align-items-center p13 prelative">
                                        <div
                                          className={`circle d-flex align-items-center justify-content-center ${submitSuccess &&
                                            "active"}`}
                                        >
                                          <h4 className="fw-bolder m-0">02</h4>
                                        </div>
                                        <p
                                          className={`sub-heading m-0 ${submitSuccess &&
                                            "active"}`}
                                        >
                                          Date & Time
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  <div className="p18 form-title prelative w-100">
                                    <p className="m-0">
                                      {currentLocation !== "IN" && submitSuccess
                                        ? "Pick a date and time that works best for you!"
                                        : "To begin, tell us a bit about yourself."}
                                    </p>
                                  </div>
                                  {currentLocation !== "IN" && submitSuccess ? (
                                    <div
                                      id="SuperOpsusdemoform"
                                      className="w-100 prelative revembed"
                                      style={{
                                        height: "440px",
                                      }}
                                    >
                                      <div className="position-absolute p18 w-100 h-100 d-flex justify-content-center align-items-center">
                                        <p className="text-center text-grey">
                                          Time slot is loading...
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <div
                                        className={`form-captcha-error position-relative ${
                                          errorText
                                            ? "d-flex justify-content-center align-items-center gap-16"
                                            : "dspnone"
                                        }`}
                                      >
                                        <img
                                          src={ErrorIcon}
                                          height="10"
                                          width="10"
                                          className="form-error-icon position-relative"
                                          alt="img"
                                        />
                                        <p className="p14 err-text m-0">
                                          {errorText}
                                        </p>
                                      </div>
                                      <FormBuilder
                                        id="sops"
                                        formClassName="forms mt6"
                                        errorClassName="error-message"
                                        inputErrorClassName="error-active"
                                        data={formdata}
                                        buttonText="GET YOUR FREE DEMO"
                                        buttonClassName="primary"
                                        buttonId="demoSubmitButton"
                                        IpStackData
                                        path={
                                          currentLocation === "IN"
                                            ? "demo"
                                            : null
                                        }
                                        endpoint={
                                          process.env.HUBSPOT_DEMO_ENDPOINT
                                        }
                                        url={process.env.HUBSPOT_DEMO_URL}
                                        formName="Demo page - Superops"
                                        sessionStorage
                                        afterSubmit={(res, items) =>
                                          handleAfterSubmit(res, items)
                                        }
                                        hiddenCountry={true}
                                        currentLocation={val =>
                                          setCurrentLocation(val)
                                        }
                                        disallowCommonDomains
                                        ErrorTextSetter={val =>
                                          setErrorText(val)
                                        }
                                        demoPage
                                      />
                                    </>
                                  )}
                                </Container>
                              </section>
                            </center>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </section>
                  <div className="marq-wrap down-to-up-5">
                    <Marquee pauseOnClick pauseOnHover gradient={false}>
                      {newField[1]?.testimonialTitle?.map((desc, i) => {
                        return (
                          <Link
                            to={newField[1]?.testimonialLink[i]}
                            target="_blank"
                          >
                            <Col lg={6} className="marq-child d-flex prelative">
                              <SVGIcon
                                name="g2_logo"
                                className="logo position-absolute"
                              />
                              <div className="p16 review-container w-100 d-flex flex-column justify-content-between">
                                <p className="text-grey review-text m-0">
                                  {newField[1]?.testimonialDescription[i]}
                                </p>
                                <div className="author-star d-flex align-items-center justify-content-between prelative">
                                  <div className="d-flex align-items-center cg-10">
                                    <img
                                      src={
                                        newField[1]?.testimonialImage[i]?.url
                                      }
                                    />
                                    <div className="w-100">
                                      <p className="review-author fw-600 m-0">
                                        {desc}
                                      </p>
                                      <div className="d-flex justify-content-between p14">
                                        {newField[1]?.testimonialName[i] !=
                                        "Nil" ? (
                                          <p className="m-0 review-job">
                                            {newField[1]?.testimonialName[i]}
                                          </p>
                                        ) : (
                                          <p className="m-0 invisible review-job">
                                            {newField[1]?.testimonialName[i]}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex star">
                                    {fiveStar.map(() => {
                                      return <SVGIcon name="star" />
                                    })}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Link>
                        )
                      })}
                    </Marquee>
                  </div>

                  <div
                    className="overlay"
                    style={{ display: showForm ? "block" : "none" }}
                  >
                    <img
                      src={Close}
                      className="close-icn"
                      alt="close"
                      onClick={() => setShowForm(false)}
                    />
                    <div className="position-relative">
                      <div
                        class="meetings-iframe-container"
                        data-src="https://meetings.hubspot.com/arjun-marella/introduction-to-superopsai?embed=true"
                      />
                    </div>
                  </div>

                  <CTAThankYou />
                </Frame>
              </>
            )
          })}
        </div>
      )}
    />
  )
}

export default GrowDemoEset

// HUBSPOT SUCCESS URL : https://superops.ai/demo/success?__hstc=20629287.65378e71cc0d7a53712c8dc264dbafda.1640597208426.1640597208426.1640597208426.1&__hssc=20629287.1.1640597208424&__hsfp=3203040867
